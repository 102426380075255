const DataCompanies = [
    {
        id:1,
        image:require("../../Assets/Images/invest-compnies/advise_min.png"),
        name:"Investment Advisory"
    },
    {
        id:2,
        image:require("../../Assets/Images/invest-compnies/accounting_min.png"),
        name:"Fund Management"
    },
    {
        id:3,
        image:require("../../Assets/Images/invest-compnies/investment_min.png"),
        name:"Impact Investment"
    },
    {
        id:4,
        image:require("../../Assets/Images/invest-compnies/company_min.png"),
        name:"Corporate Services"
    },
    {
        id:5,
        image:require("../../Assets/Images/invest-compnies/public_relations_min.png"),
        name:"Public Sector"
    },
    {
        id:6,
        image:require("../../Assets/Images/invest-compnies/home_min.png"),
        name:"Real Estate Investment"
    },
    {
        id:7,
        image:require("../../Assets/Images/invest-compnies/mobile_payment_min.png"),
        name:"Transaction Advisory"
    }
]
export default DataCompanies;