import "../../Assets/Style/Style.css";
import mainImage from "../../Assets/Images/stratigic-Outlook/Rectangle794-min_11zon.png";
import image1 from "../../Assets/Images/stratigic-Outlook/Vector-3-min.png";
import image2 from "../../Assets/Images/stratigic-Outlook/Vector-4-min.png";
import image3 from "../../Assets/Images/stratigic-Outlook/Vector-5-min.png";
import image4 from "../../Assets/Images/stratigic-Outlook/Vector-6-min.png";
import image5 from "../../Assets/Images/stratigic-Outlook/Vector-7-min.png";
import image6 from "../../Assets/Images/stratigic-Outlook/Vector-8-min.png";

import image7 from "../../Assets/Images/stratigic-Outlook/Frame33880_11zon.jpg"
const Stratigic = () => {
  return (
    <>
      <div className="container-stragic-outlook">
        <div className="row-1-main-image">
          <img src={mainImage} alt="img" />
        </div>
        <div className="row-2-flex-containor-sect">
          <h2>Strategic Outlook</h2>
          <div className="col-1-stratigic-contaier-div">
            <div className="box-same-css-stratigic">
              <div className="image-icon-stratigic-section">
                <img src={image1} alt="img11" />
              </div>
              <h2>VISION</h2>
              <p>To become the preferred choice for impact investors</p>
            </div>
            <div className="box-same-css-stratigic">
              <div className="image-icon-stratigic-section">
                <img src={image2} alt="img12" />
              </div>
              <h2>Values</h2>
              <p>Integrity, Responsibility & Value creation</p>
            </div>
            <div className="box-same-css-stratigic">
              <div className="image-icon-stratigic-section">
                <img src={image3} alt="img13" />
              </div>
              <h2>Mission</h2>
              <p>
                Our mission is to abridge the gap between the public and private
                sectors by bringing responsible investments to Gilgit Baltistan
              </p>
            </div>
            <div className="box-same-css-stratigic">
              <div className="image-icon-stratigic-section">
                <img src={image4} alt="img13" />
              </div>
              <h2>Goals</h2>
              <p>
                Our strategic goal is to foster sustainable socio-economic
                development while promoting responsible investments
              </p>
            </div>
            <div className="box-same-css-stratigic">
              <div className="image-icon-stratigic-section">
                <img src={image5} alt="img13" />
              </div>
              <h2>Objectives</h2>
              <p>
                To generate a measurable, beneficial social or environmental
                impact alongside a financial return
              </p>
            </div>
            <div className="box-same-css-stratigic">
              <div className="image-icon-stratigic-section">
                <img src={image6} alt="img13" />
              </div>
              <h2>STRATEGIC INTENTS</h2>
              <p>
                To steer private sector growth in partnership with all relevant
                stakeholders in the region
              </p>
            </div>
          </div>
        </div>
        <div className="container-outlook-div2">
          <div className="text-flex-stratigic-sect">
            <h2>Company Overview</h2>
            <p>
              A dedicated team at Apex and Company uses its operational
              knowledge to spot untapped opportunities and create profitable,
              scalable businesses that change people's lives in the neglected
              region of Gilgit Baltistan. Since the establishment of our company
              in 2021, we have helped hundreds of families in earning a
              sustainable living.
            </p>
            <p>
              When considering possible investments, we balance financial and
              impact factors equally and only pursue possibilities that have a
              good chance of meeting both. In order to analyze and evaluate
              impact, we create fundamental tools, which have shown to be a wise
              way to direct our investing agenda.
            </p>
            <p>
              We have invested in Information Technology, Sustainable Tourism,
              Quality Education, and Agriculture because we operate at the
              crossroads of need, opportunity, and experience, pursuing social,
              environmental, and financial goals. We are actively identifying
              potential business ideas that are in line with our core mandate
              and seek investors with similar objectives. We strive to
              mainstream our investments from ideation to deployment and
              post-deployment management of funds for value creation.
            </p>
          </div>
          <div className="image-flex-stratigic-outlook">
            <img src={image7} alt="images" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Stratigic;
