import React from "react";
import "../Assets/Style/swiper-slider.css";
import { GrLinkedin } from "react-icons/gr";
const Cards = ({ imge, title, desc, icon, href }) => {
  return (
    <div className="w-[100%] lg:h-[60vh] h-auto bg-[#fff] shadow-xl team_container " style={{marginBottom:"20px",paddingBottom:"20px"}}>
      <div>
        <img
          className="w-[100%] object-cover team_img1"
          src={imge}
          alt="here!!!"
        />
        <div className="flex flex-col gap-4 p-[20px] ">
          <div>
            <h2
              className="_mT16 _mB16"
              style={{
                fontFamily: "Mulish",
                fontWeight: "600",
                fontSize: "1.4vw",
                color: "#414E5E",
                textTransform: "capitalize",
              }}
            >
              {title}
            </h2>
          </div>
          <div>
            <p
              style={{
                fontSize: "1vw",
                fontWeight: "600",
                textJustify: "capitalize",
                fontFamily: "Mulish",
              }}
            >
              {desc}
            </p>
          </div>
          <div>
            <a rel="noreferrer" href={href} target="_blank" style={{color:'#0077B5',height:"43px"}}>
              {/* {icon} */}
              <img src={icon} style={{width:"25px"}} alt="icons" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cards;
