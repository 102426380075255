import React from 'react';
import { TbMailForward } from "react-icons/tb";
import { MdPhoneInTalk } from "react-icons/md";
import { BiMailSend } from "react-icons/bi";
import { ImWhatsapp } from "react-icons/im";
import { BsFacebook } from "react-icons/bs";
import { AiFillTwitterCircle } from "react-icons/ai";
import { FaInstagram } from "react-icons/fa";
import { IoLogoLinkedin } from "react-icons/io";
import { FaSquareXTwitter, FaSquareFacebook, FaLinkedin, FaSquareInstagram } from "react-icons/fa6";
import { Link } from 'react-router-dom';
import "../../Assets/Style/Style.css";
const Contact_Us = () => {
  return (
    <>
        <div className="contact_container">
          <h2>Contact Us</h2>
          <div className="flex_contact_container">
            <h3>At Apex & Co, we value your feedback and questions. To get in touch, please use one of the following options:</h3>
            <div className="social_contact">
              <p><TbMailForward /> <span>Email : </span> connect@apexnco.com </p>
              <p><MdPhoneInTalk /> <span>Phone : </span> 05811-551855 </p>
              <p><ImWhatsapp /> <span>WhatsApp : </span> +92 319 0462069 </p>
            </div>
            <div className="media_quries">
              <h3>Media Inquiries</h3>
              <p><BiMailSend />&nbsp;&nbsp; comms@apexnco.com</p>
              <h2>Follow Us on Social media</h2>
              <div className="social_icons2">
                <a href="https://www.facebook.com/apexandcompany" target="_blank" rel="noreferrer"><FaSquareFacebook style={{width:"40px",height:"40px"}} /></a>
                <a href="https://twitter.com/Apexandcompany"
                  className="facebookIcon" rel="noreferrer" target='_blank'><FaSquareXTwitter style={{width:"45px",height:"45px"}}  /></a>
                <a href="https://www.instagram.com/apexandcompany/" rel="noreferrer" target="_blank"><FaSquareInstagram style={{width:"45px",height:"45px"}}  /></a>
                <a href="https://www.linkedin.com/company/apexandcomapny/"
                  className="facebookIcon" rel="noreferrer" target="_blank"><FaLinkedin style={{width:"45px",height:"45px"}}  /></a>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default Contact_Us;