import React from 'react';
import Arrow from "./80696-arrow-down.json";
import Lottie from "react-lottie";
import DataArrow from "../ArrowData.json";
import { MdOutlineArrowBackIosNew,MdArrowForwardIos } from "react-icons/md";

const arrows1_points = {
  width: "70px",
  height: "63px",
  overflow: "hidden",
  margin: "0px auto",
  outline: "none",
  position: "absolute",
  transform: "rotate(90deg)",
};
const ArrowsOption = {
  loop: true,
  autoplay: true,
  animationData: DataArrow,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const NextArrow = ({onClick}) => {
  return (
    <div className='absolute left-[70px] -bottom-14 v z-index-99' onClick={onClick} >
        <div className='bg-[] h-[35px] w-[35px] rounded-full grid place-items-center cursor-pointer'
       
        >
         <Lottie
                options={ArrowsOption}
                style={arrows1_points}
                id="LottieId"
              />
            {/* <Lottie options={defaultOption} style={location_points} id="LottieId" /> */}
            {/* <MdArrowForwardIos /> */}
        </div>
    </div>
  )
}

export default NextArrow;