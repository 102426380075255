import React from 'react'

const Media = () => {
  return (
    <div className='h-screen flex justify-center items-center font-semibold text-3xl'>
       <h2> Comming Soon.!</h2>
    </div>
  )
}

export default Media