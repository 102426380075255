import React from "react";
import { NavLink } from "react-router-dom";
import { BiMailSend } from "react-icons/bi";
import { TbPhoneCall } from "react-icons/tb";
// import { SlLocationPin } from "react-icons/sl";
import { FaSquareXTwitter, FaSquareFacebook, FaLinkedin, FaSquareInstagram } from "react-icons/fa6";
import logoNav from "../../Assets/Images/footer_logo.png";
import "../../Assets/Style/Style.css";
import MapComponent from "../../Componants/MapComponent";
// import { MapContainer, TileLayer } from "react-leaflet";
const Footer = () => {
  return (
    <>
      <div className="container-7-footer">
        <div className="row-section-7-parent">
          <div className="logo-footer-area">
            <NavLink to="/">
              <img src={logoNav} alt="logo" />
            </NavLink>
            <p>
              Apex and Co is a group of companies for impact-driven investments,
              which promotes sustainability, equity and scalability contributing
              towards long-term transformations.
            </p>
          </div>
          <div className="social-footer-sec-7">
            <div className="links-footer-section">
              <ul className="portfolio-section-7">
                <h3>Our Initiatives</h3>
                <a href="https://gbeetechive.com/" rel="noreferrer" target="_blank">
                  www.gbeetechive.com
                </a>
                <a href="https://gbinvest.org/ " rel="noreferrer" target="_blank">
                  www.gbinvest.org
                </a>
                <a href="https://www.apexresorts.pk" rel="noreferrer" target="_blank">
                  www.apexresorts.pk
                </a>
                <a href="https://rupaniacademy.org/" rel="noreferrer" target="_blank">
                  www.rupaniacedemy.org
                </a>
              </ul>
              <ul className="find-us-section-7">
                <h3>Get In Touch</h3>
                <div className="disply-row2-7">
                  <div className="footer-row2">
                   
                    <MapComponent />
                  </div>
                  <div class="footer-row1">
                    <p>
                      <BiMailSend />
                      &nbsp;&nbsp; connect@apexnco.com
                    </p>
                  </div>
                  <div class="footer-row1">
                    <p>
                      {" "}
                      <TbPhoneCall />
                      &nbsp;&nbsp;05811-551855
                    </p>
                  </div>
                </div>
              </ul>
              <ul className="follow-us-section-7">
                <h3>Follow Us</h3>
                <div className="row-2-display-flex-7">
                  <a href="https://www.linkedin.com/company/apexandcomapny/mycompany/?viewAsMember=true " rel="noreferrer" target="_blank">
                    <FaLinkedin />
                  </a>
                  <a href="https://twitter.com/Apexandcompany" rel="noreferrer" target="_blank">
                    <i><FaSquareXTwitter /></i>
                  </a>
                  <a href="https://www.facebook.com/apexandcompany" rel="noreferrer" target="_blank">
                    <i ><FaSquareFacebook /></i>
                  </a>

                  <a href="https://www.instagram.com/apexandcompany/" rel="noreferrer" target="_blank">
                    <FaSquareInstagram />
                  </a>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
