import React, { useState } from "react";
import { ImCross } from "react-icons/im";
import { IoSettingsOutline } from "react-icons/io5";
import { GiPowerButton } from "react-icons/gi";
import { RiMenu3Line } from "react-icons/ri";
import { Link } from "react-router-dom";
import { TiThMenu } from "react-icons/ti";

const SideNav = () => {
    const sideNavLinks = [
        {
            id: 1,
            text: "News",
            linkto: "/News"
        },
        {
            id: 2,
            text: "Media",
            linkto: "/Media"

        },
        {
            id: 3,
            text: "Events ",
            linkto: "/Events"

        },
        {
            id: 4,
            text: "Career/Jobs",
            linkto: "/CareerJobs"

        },

    ];
    return (
        <>
            <div className="">
                <div className={` cursor-pointer bg-slate-50 md:w-[150px] w-[100px] flex items-center  flex-col py-[2rem] md:px-12 px-6 z-10  `}>

                    <div className="flex  flex-col items-center z-20 md:gap-[2rem] gap-1">
                        {sideNavLinks.map(({ id, text, linkto }) => {
                            return (
                                <Link to={linkto}>
                                    <div
                                        key={id}
                                        className="flex gap-x-[0.5rem] text-[16px] font-semibold rounded-lg hover:border-[0.5px] hover:border-green-500 hover:bg-gray-800 hover:text-[#fff] py-[0.8rem] px-[2rem] transition-all ease-linear delay-150 "
                                    >
                                        {text}
                                    </div>
                                </Link>
                            );
                        })}
                    </div>

                    <div className=" absolute bottom-5 md:left-[40px] flex flex-col gap-[1rem]  ">
                        <div className="flex w-[100px]">
                            <p className="flex gap-x-[0.5rem] text-[16px] font-semibold rounded-lg hover:border-[0.5px] hover:border-green-500 hover:bg-gray-800 shadow-md md:py-[0.8rem] py-2 hover:text-white md:px-[1rem] px-1 transition-all ease-linear delay-150  items-center">
                                <IoSettingsOutline className="md:text-[18px] text-sm " /> settings
                            </p>
                        </div>
                        <div className="flex  ">
                            <p className="flex gap-x-[0.5rem] text-[16px] font-semibold rounded-lg hover:border-[0.5px] hover:border-red-500 hover:bg-gray-800 shadow-md md:py-[0.8rem] py-1 md:px-[1rem] px-1 transition-all ease-linear delay-150  items-center text-[#F89251]">
                                <GiPowerButton className="md:text-[18px] text-sm" /> Log Out
                            </p>
                        </div>
                    </div>
                </div>
                {/* <div className=" z-10 absolute font-bold right-10 text-[#131313] top-10 cursor-pointer text-[25px] " onClick={() => setShow(!show)}>
                    {!show ? <TiThMenu  /> : <ImCross />}
                </div> */}
            </div>
        </>
    );
};

export default SideNav;