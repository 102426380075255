import { useEffect, useState, useRef } from "react";
import logoNav from "../../Assets/Images/logoNav.png";
import { FaSquareXTwitter, FaSquareFacebook, FaLinkedin, FaSquareInstagram } from "react-icons/fa6";
import "../../Assets/Style/Style.css";
import { ImCross } from "react-icons/im";
import { TiThMenu } from "react-icons/ti";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  NavLink,

} from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import Hamburger from "hamburger-react";
import SideNav from "../SideBar/SideBar";
const Header = () => {
  const [nav, setNav] = useState(false);
  const [show, setShow] = useState(false);

  const ref = useRef(null);

  // function for the scroll nav
  function ChangeBg() {
    if (window.scrollY >= 30) {
      setNav(true);
    } else {
      setNav(false);
    }
  }
  // onClick on service section
  const handleClick = (e) => {
    const el = document.getElementById("servicesID");
    console.log(el);
    // 👇️ (better) use a ref
    const el2 = ref.current;
    console.log(el2);
  };
  // addeventlistener in the body
  window.addEventListener("scroll", ChangeBg);
  let activeStyle = {
    textDecoration: "underline",
  };

  return (
    <>
      <nav className={nav ? "nav scroll" : "nav"}>
        <div className="navbar" id="navBar">
          <div className="logo">
            <NavLink to="/">
              <img src={logoNav} alt="logo" />
            </NavLink>
          </div>
          <input type="checkbox" id="check" />
          <label for="check" className="checkbtn">
            <Hamburger />
          </label>
          <div className="small_screen">
            <ul>
              <li>
                <NavLink exact to="/" onClick={() => { setNav(true); }} >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink exact to="/portfolio" onClick={() => { setNav(true) }}>
                  Our Initiatives
                </NavLink>
              </li>
              <li>
                <NavLink to="/approach" onClick={() => { setNav(true); }}>
                  Investment Approach
                </NavLink>
              </li>
              <li>
                <div class="dropdown">
                  About Us
                  <div className="tooltip">
                    <div class="dropdown-content">
                      <NavLink to="/Stratigic" >Company Overview</NavLink>
                      <NavLink to="/message"  >Chairman & CEO Message</NavLink>
                      <NavLink to="/ourteam"  >Our Team</NavLink>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <HashLink to="/contactUs" > Get In Touch</HashLink>
              </li>

            </ul>
            <div className="social_icon flex md:flex-row flex-col md:space-y-0 space-y-3 md:justify-end justify-center md:items-center items-center">
              <div className="social_icons_container">
                <a
                  href="https://www.linkedin.com/company/apexandcomapny/"
                  className="facebookIcon" rel="noreferrer" target="_blank"
                >
                  <FaLinkedin />
                </a>
                <a
                  href="https://twitter.com/Apexandcompany"
                  className="facebookIcon" rel="noreferrer" target="_blank"
                >
                  <FaSquareXTwitter />
                </a>
                <a href="https://www.facebook.com/apexandcompany" target="_blank" rel="noreferrer" className="facebookIcon">
                  <FaSquareFacebook />
                </a>

                <a href="https://www.instagram.com/apexandcompany/" rel="noreferrer" target="_blank" className="facebookIcon">

                  <FaSquareInstagram />
                </a>
              </div>
              <div className="font-bold ml-3 text-[#131313] cursor-pointer text-[20px] " onClick={() => setShow(!show)}>
                {!show ? <TiThMenu /> : <ImCross />}
              </div>
              {/* <div className={` ${!show} ? "top-[-120%] " : 'z-20 '`}>
                {show && <SideNav />}
              </div> */}
              <div className={`md:w-full w-auto h-full bg-gray-800 z-50 transition-opacity duration-500 ${show ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}>
                <div className="container mx-auto flex justify-end items-center h-full">
                  <div className={`md:h-[85vh] h-[60vh] absolute mt-5 right-0 top-20 rounded-[5px] shadow-2xl cursor-pointer bg-slate-50 md:w-[200px] w-[120px] flex items-center flex-col py-[2rem]  md:px-12 px-6 z-10 transition-transform duration-500 ${show ? 'translate-x-0' : 'translate-x-full'}`}>
                    <SideNav />
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Header;
