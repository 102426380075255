import React from "react";
import { useRef, useState } from "react";
import { NavLink } from "react-router-dom"
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import headerImage from "../../Assets/Images/bg_main_img.png";
// icons
import DataCompanies from "../../Componants/Companies/DataCompanies";
import DottedImage from "../../Assets/Images/imgs1.png";
import inversterCompnies from "../../Assets/Images/imgs2.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Navigation, Autoplay } from "swiper";
import SwiperCore from "swiper";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import DataLocation from "../../locationData.json";
import DataArrow from "../../ArrowData.json";

import Data_flag from "../../flag1.json";
import Lottie from "react-lottie";
import slideImage1 from "../../Assets/Images/slider-invster-imgs/cardiogram1-min.jpg";
import slideImage2 from "../../Assets/Images/slider-invster-imgs/Vector3-min.png";
import slideImage3 from "../../Assets/Images/slider-invster-imgs/soup11-min.jpg";
import slideImage4 from "../../Assets/Images/slider-invster-imgs/quality1-min.jpg";
import slideImage5 from "../../Assets/Images/slider-invster-imgs/increasing1-min.jpg";
import slideImage6 from "../../Assets/Images/slider-invster-imgs/family1-min.jpg";
import next from "../../Assets/Images/next1.png";
import prev from "../../Assets/Images/prev1.png";
// section-5 import images
import saving1_min from "../../Assets/Images/section-5-6/saving1-min.png";
import suitcase1_min from "../../Assets/Images/section-5-6/suitcase1-min.png";
import workshop1_min from "../../Assets/Images/section-5-6/workshop1-min.png";
import stock_market1min from "../../Assets/Images/section-5-6/stock-market1-min.png";
import impact_img from "../../Assets/Images/Rectangle_min.png";
import logo1 from "../../Assets/Images/Vector_1.png";
import logo2 from "../../Assets/Images/Vector_2.png";
import logo3 from "../../Assets/Images/Vector_3.png";
// section-6
import videos from "../../Assets/Video/gbtec.mp4";
import videos1 from "../../Assets/Video/apex.mp4";
import Group33804_min from "../../Assets/Images/Group33804-min1_11zon.jpg";
import { Link } from "react-router-dom";
const Home = () => {
  const location_point = {
    width: "55px",
    height: "65px",
    overflow: "hidden",
    margin: "0px auto",
    outline: "none",
    position: "absolute",
    top: "15%",
    left: "39.8%",
    right: "59%",
  };
  const location_points = {
    width: "60px",
    height: "65px",
    overflow: "hidden",
    margin: "0px auto",
    outline: "none",
    position: "absolute",
    top: "83%",
    right: "-2%",
  };
  const arrows1_points = {
    width: "80px",
    height: "65px",
    overflow: "hidden",
    margin: "0px auto",
    outline: "none",
    position: "absolute",
    top: "65%",
    right: "17%",
    transform: "rotate(-45deg)",
  };

  const arrows2_points = {
    width: "80px",
    height: "65px",
    overflow: "hidden",
    margin: "0px auto",
    outline: "none",
    position: "absolute",
    top: "26%",
    right: "35%",
    left: "60.5%",
    transform: "rotate(-60deg)",
  };
  const swiperRef = useRef();
  const [counterOn, setCounterOn] = useState(false);
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DataLocation,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const defaultOption = {
    loop: true,
    autoplay: true,
    animationData: Data_flag,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };
  const ArrowsOption = {
    loop: true,
    autoplay: true,
    animationData: DataArrow,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {/* background image section  */}
      <div className="main_container">
        <div className="relative w-full h-full">
          <video autoPlay loop muted className="absolute inset-0 w-full h-full object-cover">
            <source src={videos1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>
        {/* <LazyLoadImage
          src={headerImage}
          effect="blur"
          width={headerImage.width}
          visibleByDefault={"../../Assets/Images/bg_main_img.png"}
        />
        <Lottie options={defaultOptions} style={location_point} id="LottieId" />
  <div className="container_parent"> */}
        <div className="row1 absolute sm:left-20 left-2">
          <h3>People. Nature. Value</h3>
          <h2>Investments That <br /> Transform Lives</h2>
        </div>
        {/*
          <div className="row2">
            <div className="col-container1">
              <div className="col1">
                {" "}
                <p>Rupani Academy</p>
              </div>
              <div className="col1">
                <p>GBeeTechive</p>
              </div>
              <div className="col1">
                {" "}
                <p>GB Invest</p>
              </div>
              <div className="col1">
                {" "}
                <p>Le Yurt</p>
              </div>
            </div>
            <div className="col-img1" id="servicesID">
              <img src={DottedImage} alt="img" />
            </div>
            <div className="lottie_div">
              <Lottie
                options={ArrowsOption}
                style={arrows1_points}
                id="LottieId"
              />
              <Lottie
                options={ArrowsOption}
                style={arrows2_points}
                id="LottieId"
              />
              <Lottie
                options={defaultOption}
                style={location_points}
                id="LottieId"
              />
            </div>
          </div>
        </div> */}
      </div>

      {/* Next Section coding start here  */}
      <div className="container_compnies">
        <h2>Our Services</h2>
        <div className="row_compneis">
          {DataCompanies.map((item) => (
            <div className="col1_compnes">
              <img src={item.image} alt="advise" />
              <p>{item.name}</p>
            </div>
          ))}
        </div>
      </div>
      {/* impact Investing sectionn start here!!! */}
      {/* <div className="main-container-3">
        <div className="row-section-3-parent">
          <div className="col-1-image1-sec">
            <img src={inversterCompnies} alt="imges" />
          </div>
          <div className="col-2-contant-sec">
            <h2>Why Impact Investing?</h2>
            <p>
              Pressure on the environment, the prospect of irreversible climate
              change, and a rising number of people who are denied the means to
              fulfill basic needs require a fundamental change in the way we
              live and how we invest. There is an extraordinary opportunity to
              harness a growing wave of optimism and awareness as more and more
              investors are looking for a long-term partner with whom to invest.
            </p>
            <div className="box-3-section">
              <div className="box1 boxs-same-css">
                <span>Direct Investment In Real Economy</span>
                <p>
                  Investors can access businesses or initiatives that produce
                  real social and environmental benefits by engaging in impact
                  investing... <br />
                  <Link to="/Impact_investing1">Read More</Link>
                </p>
              </div>
              <div className="box2 boxs-same-css">
                <span>Long Term Financial Performance</span>
                <p>
                  Numerous studies demonstrate that over the long term, impact
                  investment outperforms traditional investing, but with the
                  added...
                  <br /> <a>Read More</a>
                </p>
              </div>
              <div className="box3 boxs-same-css">
                <span>
                  Full <br /> Tranparency
                </span>
                <p>
                  Knowing exactly where and how the money is being invested is a
                  must to ensure transparency. <br /> It advances the
                  accountability...
                  <br />
                  <a>Read More</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="main-container_3">
        <div className="image_impact_investing">
          <div className="impact_center_flex">
            <h2>Why Impact Investing?</h2>
            <div className="cards_impact_container">
              <div className="impact_cards">
                <img src={logo3} alt="logo...." />
                <h2>
                  Direct Investment <br /> In Real Economy
                </h2>
                <p>
                  Investors can access businesses or initiatives that produce
                  real social and environmental benefits by engaging in impact
                  investing...{" "}
                </p>
                <NavLink to="/Impact_investing1">Read More</NavLink>
              </div>
              <div className="impact_cards">
                <img src={logo2} alt="logo...." />
                <h2>
                  Long Term Financial <br /> Performance
                </h2>
                <p>
                  Numerous studies demonstrate that over the long term, impact
                  investment outperforms traditional investing, but with the
                  added
                </p>
                <NavLink to="/Impact_investing1">Read More</NavLink>
              </div>
              <div className="impact_cards">
                <img src={logo1} alt="logo...." />
                <h2>
                  Full <br /> Transparency
                </h2>
                <p>
                  Knowing exactly where and how the money is being invested is a
                  must to ensure transparency. It advances the accountability...{" "}
                </p>
                <NavLink to="/Impact_investing1">Read More</NavLink>
              </div>
            </div>
          </div>
        </div>
        <div className="impact_paragraph">
          <p>
            Pressure on the environment, the prospect of irreversible climate
            change, and a rising number of people who are denied the means to
            fulfill basic needs require a fundamental change in the way we live
            and how we invest. There is an extraordinary opportunity to harness
            a growing wave of optimism and awareness as more and more investors
            are looking for a long-term partner with whom to invest.
          </p>
          <NavLink to="/Impact_investing1">Read More</NavLink>
        </div>
      </div>

      {/********************* * slider componant start here ************/}
      <div className="container-invester-guide">
        <img
          src={require("../../Assets/Images/slider-invster-imgs/Rectangle782-min.jpg")}
          alt="....."
          className="-z-20"
        />
        <div className="row1-parent-invter-guide">
          <div className="txt-invster-g">
            <h2>Investors Guide</h2>
            <p className="invester-para-12">
              We gauge the extent of our impact using the Sustainable
              Development Goals of the UN and determine how we may further
              increase the role of the investment as the driver of impact, with
              an inbuilt focus on prerequisites in growth markets.
            </p>
            <Link to="/investerGuide" className="slide-btn2">
              Read More
            </Link>
          </div>
          <div className="slider-invter-guide-sec z-10">
            <Swiper
              spaceBetween={30}
              slidesPerView={5}
              loop
              onSwiper={(swiper) => console.log(swiper)}
              modules={[Navigation, Autoplay]}
              onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
              }} 
              autoplay={{
                delay: 3000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                425: {
                  width: 425,
                  slidesPerView: 1,
                },
                576: {
                  width: 576,
                  slidesPerView: 2,
                },
                768: {
                  width: 768,
                  slidesPerView: 3,
                },
                844: {
                  width: 844,
                  slidesPerView: 4,
                },
                1024: {
                  width: 1024,
                  slidesPerView: 5,
                },
              }}
              className="Swiper-class-custem  ">
              <SwiperSlide className="slider-items">
                <img src={slideImage1} alt="imglogo" />
                <p>Good Health </p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage2} alt="imglogo" />
                <p>Gender Equality</p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage3} alt="imglogo" />
                <p>No Hunger</p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage4} alt="imglogo" />
                <p>Quality Education</p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage5} alt="imglogo" />
                <p>Economy Growth</p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage6} alt="imglogo" />
                <p>No Poverty</p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage1} alt="imglogo" />
                <p>Good Health </p>
              </SwiperSlide>
              <SwiperSlide className="slider-items">
                <img src={slideImage4} alt="imglogo" />
                <p>Quality Education</p>
              </SwiperSlide>
            </Swiper>
            <div className="next_prev_container">
              <a
                className="prev-custom-css"
                onClick={() => swiperRef.current?.slidePrev()}
              >
                <img src={prev} alt="img" />
              </a>
              <a
                className="next-custom-css"
                onClick={() => swiperRef.current?.slideNext()}
              >
                <img src={next} alt="img" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* section-5 developement */}
      <div className="container-5-section">
        <div className="row1-section-5-parent">
          <div className="col-box1-section-5">
            <div className="image-section-5">
              <img src={saving1_min} alt="imge" />
            </div>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <h3 className="h3-heading">
                $
                {counterOn && (
                  <CountUp start={0} end={15.03} duration={4} delay={0} />
                )}{" "}
                <span className="span-heading1">Mil</span>
              </h3>
            </ScrollTrigger>
            <p>Total Investments Mobilized</p>
          </div>
          <div className="col-box1-section-5">
            <div className="image-section-5">
              <img src={suitcase1_min} alt="imge" />
            </div>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <h3 className="h3-heading">
                {counterOn && (
                  <CountUp start={0} end={3566} duration={4} delay={0} />
                )}{" "}
              </h3>
            </ScrollTrigger>
            <p>JOBS AND LIVELIHOOD SUPPORTED</p>
          </div>
          <div className="col-box1-section-5">
            <div className="image-section-5">
              <img src={workshop1_min} alt="imge" />
            </div>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <h3 className="h3-heading">
                {counterOn && (
                  <CountUp start={0} end={2500} duration={4} delay={0} />
                )}{" "}
              </h3>
            </ScrollTrigger>
            <p>SKILL DEVELOPMENT TRAININGS</p>
          </div>
          <div className="col-box1-section-5">
            <div className="image-section-5">
              <img src={stock_market1min} alt="imge" />
            </div>
            <ScrollTrigger
              onEnter={() => setCounterOn(true)}
              onExit={() => setCounterOn(false)}
            >
              <h3 className="h3-heading">
                0
                {counterOn && (
                  <CountUp start={0} end={9} duration={4} delay={0} />
                )}{" "}
              </h3>
            </ScrollTrigger>
            <p>Total Investment</p>
          </div>
        </div>
      </div>

      {/* section-6 start here  */}
      <div className="container-6-sect">
        <div className="row-6-section-parent">
          <div className="col-txt-section-6">
            <h2>Investors are Our Partners not Competitors</h2>
            <p>
              We are a hands-on investor with operational expertise in the
              Gilgit Baltistan region. We know what we are doing operationally,
              strategically, and tactically.
            </p>
            <p>
              To ensure that investments always have a positive effect, we
              operate with the highest levels of transparency.
            </p>
            <p>
              As an agile team, we focus on establishing partnerships based on
              mutual benefit and accept personal responsibility
            </p>
            <p className="bottom-class6">
              We are prepared to support your company, fully committed to
              high-performing investment and a lasting impact.
            </p>
          </div>
          <div className="col-image-section-6">
            <img src={Group33804_min} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};
export default Home;
