import React from 'react';
import "../../Assets/Style/swiper-slider.css";
import MySlider from "../../Componants/MySlider";
const Team = () => {
  return (
    <div className='container-team'>
      <h2 className='font-bold color-[#414E5E] text-[30px] text-center mt-[40px] mb-[40px]'>Our Team</h2>
      <MySlider />
    </div>
  )
}

export default Team;

