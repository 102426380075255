import React from "react";
import Image1 from "../../Assets/Images/portfolio-img/footer_logo_1_min.png";
import Image2 from "../../Assets/Images/portfolio-img/cropped_logo_1_min.png";
import Image3 from "../../Assets/Images/portfolio-img/logo_portfolio_1_min.png";
import Image4 from "../../Assets/Images/portfolio-img/navbar_Rupani_Logo1_min.png";
import Image5 from "../../Assets/Images/portfolio-img/apex_hotel_resort.png";
import Image6 from "../../Assets/Images/portfolio-img/apex_land.png";
import Image7 from "../../Assets/Images/portfolio-img/apex_cottage.png";
import Image8 from "../../Assets/Images/portfolio-img/apex_land.png";
import Image9 from "../../Assets/Images/portfolio-img/apex_tourism.png";
const Portfolio = () => {
  return (
    <>
      <div className="container-portfolio">
        <h1>portfolios</h1>
        {/* <div className="row-flex-portfolio-div">
          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="https://www.gbinvesting.com/" target="_blank">
                <img
                  src={Image2}
                  alt="Gb-invest...img"
                  style={{ width: "300px" }}
                />
              </a>
            </div>
            <div className="popup-text">
              <h3>GB Invest</h3>
              <p>
                GB Invest is a private sector initiative that aims to mobilize
                private impact investments to support equitable and sustainable
                growth in Gilgit-Baltistan…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>
          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="http://www.rupaniacademy.org/" target="_blank">
                <img
                  src={Image4}
                  alt="Rubani Acedemy....img"
                  // style={{ width: "100px" }}
                />
              </a>
            </div>
            <div className="popup-text">
              <h3>Rupani Acedemy</h3>
              <p>
                Rupani Academy is based on an ideal to abridge the hiatuses in
                conventional ecosystem of education. Our years of experience in
                the education field persuaded us to move forward and take the
                responsibility of setting a new trend…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>
          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="http://leyurt.com/" target="_blank">
                <img
                  src={Image1}
                  alt="leyurt....img"
                  style={{ width: "100px" }}
                />
              </a>
            </div>

            <div className="popup-text">
              <h3>Le-Yurt</h3>
              <p>
                Le yurt is a trading name of Apex Hotels and Resorts (Pvt)
                Limited that aims to provide quality residence in Gilgit
                Baltistan while being eco-friendly and sustainable…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>

          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="https://gbeetechive.com/" target="_blank">
                <img
                  src={Image3}
                  alt="Gebeetechive.....img"
                  style={{ width: "300px" }}
                />
              </a>
            </div>

            <div className="popup-text">
              <h3>GBeeTechive</h3>
              <p>
                GbeeTechive is a purpose-driven consulting organization focused
                on driving customer adoption to digital Marketing strategies,
                TeleHealth, Bookkeeping, Health Transcriptions…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>
          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="https://gbeetechive.com/" target="_blank">
                <img
                  src={Image5}
                  alt="Gebeetechive.....img"
                  style={{ width: "250px" }}
                />
              </a>
            </div>

            <div className="popup-text">
              <h3>GB Invest</h3>
              <p>
                GB Invest is a private sector initiative that aims to mobilize
                private impact investments to support equitable and sustainable
                growth in Gilgit-Baltistan…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>
          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="https://gbeetechive.com/" target="_blank">
                <img
                  src={Image6}
                  alt="Gebeetechive.....img"
                  style={{ width: "100px" }}
                />
              </a>
            </div>
            <div className="popup-text">
              <h3>GB Invest</h3>
              <p>
                GB Invest is a private sector initiative that aims to mobilize
                private impact investments to support equitable and sustainable
                growth in Gilgit-Baltistan…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>
          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="https://gbeetechive.com/" target="_blank">
                <img
                  src={Image7}
                  alt="Gebeetechive.....img"
                  style={{ width: "100px" }}
                />
              </a>
            </div>

            <div className="popup-text">
              <h3>GB Invest</h3>
              <p>
                GB Invest is a private sector initiative that aims to mobilize
                private impact investments to support equitable and sustainable
                growth in Gilgit-Baltistan…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>

          <div className="col1-Box-div-fortfolio">
            <div className="front">
              <a href="https://gbeetechive.com/" target="_blank">
                <img
                  src={Image9}
                  alt="Gebeetechive.....img"
                  style={{ width: "250px" }}
                />
              </a>
            </div>

            <div className="popup-text">
              <h3>GB Invest</h3>
              <p>
                GB Invest is a private sector initiative that aims to mobilize
                private impact investments to support equitable and sustainable
                growth in Gilgit-Baltistan…
              </p>
              <a href="#">Open Link</a>
            </div>
          </div>
        </div> */}

        <div class="container max-w-7xl w-[90%] mx-auto">
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image2} alt="..." width="250px" />
                </div>
              </div>
            </div>

            <div class="slide slide2">
              <div class="content">
                <h3>GB Invest</h3>

                <p>
                  GB Invest is a private sector initiative that aims to mobilize
                  private impact investments to support equitable and
                  sustainable growth in Gilgit-Baltistan…
                </p>
                <a
                  href="https://gbinvest.org/ " rel="noreferrer" target="_blank"
                >
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image4} alt=".." width="100px" />
                </div>
              </div>
            </div>

            <div class="slide slide2">
              <div class="content">
                <h3>Rupani Acedemy</h3>

                <p>
                  Rupani Academy is based on an ideal to abridge the hiatuses in
                  conventional ecosystem of education. Our years of experience
                  in the education field...
                </p>
                <a href="https://rupaniacademy.org/" rel="noreferrer" target="_blank">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image1} alt="..." width="150px" />
                </div>
              </div>
            </div>
            <div class="slide slide2">
              <div class="content">
                <h3>Le-Yurt</h3>
                <p>
                  Le yurt is a trading name of Apex Hotels and Resorts (Pvt)
                  Limited that aims to provide quality residence in Gilgit
                  Baltistan while being eco-friendly and sustainable…
                </p>
                <a href="https://leyurt.com" rel="noreferrer" target="_blank">
                  Read More
                </a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image3} alt="" width="250px" />
                </div>
              </div>
            </div>

            <div class="slide slide2">
              <div class="content">
                <h3>GBeeTechive</h3>

                <p>
                  GbeeTechive is a purpose-driven consulting organization
                  focused on driving customer adoption to digital Marketing
                  strategies..
                </p>
                <a href="https://gbeetechive.com/" rel="noreferrer" target="_blank">
                  Read More
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="container max-w-7xl w-[90%] mx-auto" id="container_2">
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image5} alt="..." width="250px" />
                </div>
              </div>
            </div>

            <div class="slide slide2">
              <div class="content">
                <h3>Apex Hotels</h3>

                <p>e offer luxurious, eco-friendly accommodations in four unique locations across the region, each designed to immerse visitors in the rich cultural heritage of the ancient Silk Route.</p>
                <a href="https://www.apexresorts.pk" rel="noreferrer" target="_blank">Read More</a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image9} alt="" width="250px" />
                </div>
              </div>
            </div>

            <div class="slide slide2">
              <div class="content">
                <h3>ApexTOURISM</h3>

                <p>Whether you seek adrenaline-pumping activities or peaceful encounters with nature, Apex Tours and Adventure has something for every adventurer.</p>
                <a href="https://www.apexwonders.com/" rel="noreferrer" target="_blank">Read More</a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image6} alt=".." width="100px" />
                </div>
              </div>
            </div>

            <div class="slide slide2">
              <div class="content">
                <h3>Apex Land</h3>

                <p>Coming Soon......</p>
                <a href="#">Read More</a>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="slide slide1">
              <div class="content">
                <div class="icon">
                  <img src={Image7} alt="..." width="150px" />
                </div>
              </div>
            </div>
            <div class="slide slide2">
              <div class="content">
                <h3>Apex FARMS</h3>
                <p>Coming Soon....</p>
                <a href="#">Read More</a>
              </div>
            </div>
          </div>
        
        </div>
      </div>
    </>
  );
};

export default Portfolio;
