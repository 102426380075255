import React from "react";
import invster_Guide from "../../Assets/Images/invester-Guide/investerGide1.png";
const InvesterGuide = () => {
  return (
    <>
      <div className="container-invstr-guide">
        <div className="row-1-parent-flex">
          <div className="col-1-txt-section-div">
            <h2>Investors Guide</h2>
            <p>
              We gauge the extent of our impact using the Sustainable
              Development Goals of the UN and determine how we may further
              increase the role of the investment as the driver of impact, with
              an inbuilt focus on prerequisites in growth markets
            </p>
            <p>
              Our research and development teams conduct extensive research on
              compiling pre-feasibility reports on potential business ideas in
              the region. We approach our investors with ready-to-invest pitches
              that are not only aligned with the UN SDG goals but also offer a
              good return on investment.
            </p>
            <p>
              It takes a lot of time, knowledge, and resources to achieve
              financial success, which is a challenging and perplexing process.
              Our services are intended to assist you in safeguarding,
              preserving, and pursuing your money by using wise investment
              techniques to help you achieve your financial goals.
            </p>
            <p>
              To improve the likelihood that your financial goals will be
              achieved, we employ a clear, time-tested process. Your
              requirements, goals, time schedule, and risk tolerance all play a
              part in how we design our method
            </p>
          </div>
          <div className="col-2-image-section-div">
            <img src={invster_Guide} alt="img" />
          </div>
        </div>
      </div>
    </>
  );
};


export default InvesterGuide;