import Tender from "../../Assets/Images/tender/tender.jpg"

function PopupComponent() {

    return (
        <div className='h-screen flex justify-center items-center font-semibold text-3xl'>
            <h2> Comming Soon.!</h2>
        </div>
    );
}

export default PopupComponent;
