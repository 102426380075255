import React from "react";
import "../../Assets/Style/Style.css";
import ChairmanImg from "../../Assets/Images/CEO-IMG/Chairman-img.png";
import CEOImg from "../../Assets/Images/CEO-IMG/CEO-min-img.png";
const CEO_Message = () => {
  return (
    <>
      <div className="container-chairman-mess1">
        <div className="row-1-chairman-messag-container" id="padding-top-message">
          <div className="img-charmain-div">
            <img src={ChairmanImg} alt="imge" />
          </div>
          <div className="txt-chairman-messg-div">
            <h2>Chairman’s Message</h2>
            <p>
              “My passion is contributing back to my country, Pakistan. Apex &
              Co. is one my many endeavors for socio-economic development of the
              region by promoting responsible investments and private sector-led
              growth. With an overall 50 million USD commitment for
              impact-driven projects in Pakistan, we have invested in Technology
              for meaningful change, Education for transforming future
              generation, Eco-tourism for Sustainable development and,
              Agriculture for self-sufficiency and prosperity. We as a group
              believe in three principles “People. Nature . Value”. We will
              promote impact investments which will bring meaningful changes in
              the lives of people considerate of natural environments reaping
              value for the society as a whole.”
            </p>
            <h3>Nasrudin Rupani</h3>
            <h5>Chairman, Rupani Initiatives</h5>
          </div>
        </div>
      </div>
      <div className="container-chairman-mess1" id="container-CEO-messag1">
        <div
          className="row-1-chairman-messag-container"
          id="ceo-message-parent-flex-con"
        >
          <div className="img-charmain-div" id="id-image-ceo-container">
            <img src={CEOImg} alt="imge" />
          </div>
          <div className="txt-chairman-messg-div" id="txt-id-ceo-contaier">
            <h2>Group-CEO’s Message</h2>
            <p>
              “Our goal at Apex & Co and its’ subsidiaries is to become a beacon
              of change by promoting responsible investments in the region. We
              believe in local partnerships, creating synergies between the
              public and private sector, promoting innovation and quality
              service delivery. Our aim is to unleash the economic freedoms for
              the local communities by bringing in investments for a sustainable
              private sector growth. We want to become a partner of choice for
              all the investors; a nexus of need, opportunity, and experience.
              Our portfolio includes projects in Education, Technology,
              Hospitality, Minerals and Mines, Agriculture Value Chains, and
              Trade & Commerce which are adding value to the local economy and
              ensures good returns to the investors. ”
            </p>
            <h3>Mr. Waseem Samad</h3>
            <h5>Group - Chief Executive Officer</h5>
          </div>
        </div>
      </div>
    </>
  );
};

export default CEO_Message;
