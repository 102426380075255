import React from "react";
import "../../Assets/Style/Style.css";

import advise_min from "../../Assets/Images/Approach/advise_min.png";
import public_relation from "../../Assets/Images/Approach/public-relations1-min.png";
import office_1 from "../../Assets/Images/Approach/office1-min.png";
import investment_min from "../../Assets/Images/Approach/investment_min.png";

const Approach = () => {
  return (
    <>
      <div className="container-approach-section">
        <div className="row-1-fix-section">
          <div className="col-1-heading">
            <h2>Our Approach</h2>
          </div>
        </div>
        <div className="row-2-items-sec">
          <div className="col-1-box-div-approch">
            <div className="img-approach-container">
              <img src={advise_min} alt="img" />
            </div>
            <h3>INVESTMENT PHILOSOPHY</h3>
            <p>
              We are motivated by the ambition to make the best use of money.
              The entire investment process is steered by an experienced board
              to ensure value for money through a structured approach and best
              governance practices.
            </p>
          </div>
          <div className="col-1-box-div-approch">
            <div className="img-approach-container">
              <img src={public_relation} alt="img" />
            </div>
            <h3>BALANCING FINANCIAL AND SECTOR EXPERTISE</h3>
            <p>
              We are motivated by the ambition to make the best use of money.
              The entire investment process is steered by an experienced board
              to ensure value for money through a structured approach and best
              governance practices.
            </p>
          </div>
          <div className="col-1-box-div-approch">
            <div className="img-approach-container">
              <img src={office_1} alt="img" />
            </div>
            <h3>CONTRIBUTING TO SOCIETY</h3>
            <p>
              In order to achieve a number of ambitious goals i.e. elevate
              poverty, safeguard the environment, and promote prosperity for all
              in the region, we believe that the financial sector has a crucial
              role to play.
            </p>
          </div>
          <div className="col-1-box-div-approch">
            <div className="img-approach-container">
              <img src={investment_min} alt="img" />
            </div>
            <h3>TARGETING HEALTHY FINANCIAL RETURNS</h3>
            <p>
              We invest with the intention to realize positive change, combined
              with a long-term healthy financial return. This intention is our
              starting point and is driven by the notion that money is a means
              and not an end in itself. Focusing on both social and financial
              returns will result in an optimal allocation of capital, equally
              for all stakeholders.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Approach;
