import React from "react";
import Lottie from "react-lottie";
// import { MdOutlineArrowBackIosNew } from "react-icons/md";
import DataArrow from "../ArrowData.json";
const arrows1_points = {
  width: "70px",
  height: "63px",
  overflow: "hidden",
  margin: "0px auto",
  outline: "none",
  position: "absolute",
  transform: "rotate(-90deg)",
};
const ArrowsOption = {
  loop: true,
  autoplay: true,
  animationData: DataArrow,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
const PrevArrow = ({ onClick }) => {
  return (
    <div className="absolute left-[0px] -bottom-14 z-index-99" onClick={onClick}>
      <div
        className="bg-[] h-[35px] w-[35px] rounded-full grid place-items-center cursor-pointer"
      >
        <Lottie options={ArrowsOption} style={arrows1_points} id="LottieId" />{" "}
        {/* <MdOutlineArrowBackIosNew /> */}
      </div>
    </div>
  );
};

export default PrevArrow;
