import react from "react";

const Impact_investing = () => {
  return (
    <>
      <div className="Impact_investing">
        <div className="container-impact_investing">
          <h2>Why Impact Investing?</h2>
          <p>
            Pressure on the environment, the prospect of irreversible climate
            change, and a rising number of people who are denied the means to
            fulfill basic needs require a fundamental change in the way we live
            and how we invest. There is an extraordinary opportunity to harness
            a growing wave of optimism and awareness as more and more investors
            are looking for a long-term partner with whom to invest.
          </p>
        </div>
          <div className="Impact_investing-row1">
            <div className="col-1-impact_investing">
                <h4 id="id_impactSpan"> Direct Investment In Real Economy</h4> 
              <p>
                Investors can access businesses or initiatives that produce real
                social and environmental benefits by engaging in impact
                investing. We give entrepreneurs and businesses the resources-
                capital, knowledge, and network- they need to add and generate
                real value that helps society and the real economy.
              </p>
            </div>
            <div className="col-1-impact_investing">
                <h4 id="id_impactSpan">Long Term Financial Performance</h4> 
              <p>
                Numerous studies demonstrate that over the long term, impact
                investment outperforms traditional investing, but with the added
                benefits of delivering real value and improving quality of life
                for the masses. We think the investment sector requires a
                paradigm shift, moving away from short-term financial rewards
                and towards long-term investments. The only form of investing we
                undertake is impact investing, which has the ability to cause a
                paradigm shift in our region.
              </p>
            </div>
            <div className="col-1-impact_investing">
                <h4 id="id_impactSpan">Full <br /> Transparency</h4>
              <p>
                Knowing exactly where and how the money is being invested is a
                must to ensure transparency. It advances the accountability of
                fund managers and Knowing exactly where and how the money is
                being invested is a must to ensure transparency. It advances the
                accountability of fund managers and
              </p>
            </div>
          </div>
      </div>
    </>
  );
};

export default Impact_investing;